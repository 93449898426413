import React from "react"
import { Card, CardMedia, Grid, Typography } from "@mui/material"
const BenefitCard = ({ item }) => {
  return (
    <Card>
      <Grid
        container
        columnSpacing={2}
        sx={{
          height: { sm: "100px", md: "200px", lg: "230px" },
          display: "flex",
          alignItems: "center",
          padding: 1,
        }}
      >
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <CardMedia
            component="img"
            sx={{
              borderRadius: 1,
              height: { xs: "52px", sm: "44px", md: "104px" },
              objectFit: "contain",
            }}
            image={item.image}
            alt={item.name}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography
            sx={{
              fontSize: { xs: "16px", sm: "16px", md: "25px", lg: "40px" },
              color: "#163BBF",
              fontFamily: "Roboto Condensed, sans-serif",
              fontWeight: 700,
            }}
            gutterBottom
          >
            {item.name}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "13px", sm: "10px", md: "16px", lg: "22px" },
              fontFamily: "Roboto Condensed, sans-serif",
              fontWeight: 400,
            }}
          >
            {item.description}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  )
}

export default BenefitCard
