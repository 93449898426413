import { Container, Typography, Grid } from "@mui/material"
import React from "react"
import BenefitCard from "./UseCasesCard/BenefitCard"
import Performance from "../../images/Performance.png"
import Availability from "../../images/Availability.png"
import Scalability from "../../images/Scalability.png"
import OpenSource from "../../images/Open-Source.png"

const benefits = [
  {
    name: "Scalability",
    description:
      "Apache Cassandra provides high scalability and fault-tolerance, making it ideal for managing large amounts of data in a distributed environment.",
    image: Scalability,
  },
  {
    name: "Performance",
    description:
      "Apache Cassandra is highly performant and can handle a massive amount of read and write operations, making it a popular choice for real-time applications and services.",
    image: Performance,
  },
  {
    name: "Availability",
    description:
      "With its flexible data model, Apache Cassandra allows for seamless data replication and partitioning, ensuring that data is always available and accessible.",
    image: Availability,
  },
  {
    name: "Open-Source",
    description:
      "Apache Cassandra is open-source and has a large community of developers and contributors, ensuring continuous development and improvement of the platform.",
    image: OpenSource,
  },
]
function UseCasesBenefits() {
  return (
    <Container maxWidth="xl" sx={{ paddingY: 5 }}>
      <Typography
        sx={{
          fontFamily: "Roboto condensed,sans-serif",
          fontSize: { xs: "16px", md: "40px" },
          fontWeight: 700,
          marginBottom: { xs: 3, lg: 4 },
          color: "#383D3B",
        }}
      >
        <span style={{ color: "#FFA62B" }}>Benefits</span> of using Apache
        Cassandra
      </Typography>
      <Grid container spacing={2}>
        {benefits.map((item, key) => (
          <Grid item key={key} xs={12} sm={6}>
            <BenefitCard item={item} />
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default UseCasesBenefits
