import React from "react"
import { Card, Grid, Typography } from "@mui/material"

const NewUseCasesCard = ({ item, image }) => {
  return (
    <Card>
      <Grid
        container
        sx={{
          height: { sm: "150px", md: "200px", lg: "300px" },
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <img
            style={{ maxWidth: "85%" }}
            src={image}
            alt={item.data.Name}
            className="image"
          />
        </Grid>
        <Grid item xs={8}>
          <Typography
            sx={{
              fontSize: { sm: "12px", md: "25px" },
              color: "black",
              marginTop: 2,
            }}
            gutterBottom
            fontFamily="Roboto Condensed, sans-serif"
            fontWeight={500}
          >
            {item.data.Name}
          </Typography>
          <Typography
            className="textTruncate-4"
            sx={{ fontSize: { sm: "10px", md: "16px" } }}
          >
            {item.data.Description}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  )
}

export default NewUseCasesCard
