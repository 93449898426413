import { Grid, Box, Pagination, Container, Typography } from "@mui/material"
import React, { useState } from "react"
import NoPreviewImg from "../../images/NoPreviewImage.png"
import imgFilenameMap from "../../constants/use-case-logo-names.js"
import NewUseCasesCard from "./UseCasesCard/NewUseCaseCard"
function UseCasesPagination({ data }) {
  const [currentPage, setCurrentPage] = useState(1)
  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  const startIndex = (currentPage - 1) * 6
  const endIndex = startIndex + 6
  const paginatedData = data.slice(startIndex, endIndex)
  return (
    <Container maxWidth="xl" sx={{ marginY: { xs: "20px" } }}>
      <Typography
        sx={{
          fontFamily: "Roboto condensed,sans-serif",
          fontSize: { xs: "16px", md: "40px" },
          fontWeight: 700,
          marginBottom: { xs: 3, lg: 4 },
          color: "#383D3B",
        }}
      >
        Who is <span style={{ color: "#FFA62B" }}>using Apache Cassandra</span>
      </Typography>
      <Grid container spacing={{ xs: 2, sm: 1.5, md: 3 }}>
        {paginatedData.map((item,index) => {
          const key = item.data.Company_Logo_Filename
          const fileName = imgFilenameMap[key]
          const filePath = fileName ? `/images/logos/${fileName}` : NoPreviewImg
          return (
            <Grid key={item.id || index} item xs={12} sm={6}>
              <NewUseCasesCard  item={item} image={filePath} />
            </Grid>
          )
        })}
      </Grid>
      <Box sx={{ marginY: 3 }}>
        <Pagination
          count={Math.ceil(data.length / 6)}
          page={currentPage}
          onChange={handlePageChange}
        />
      </Box>
    </Container>
  )
}

export default UseCasesPagination
