import React, { useState } from "react"
import { Box, Button, Grid, Typography } from "@mui/material"
import NoPreviewImg from "../../../images/NoPreviewImage.png"
import imgFilenameMap from "../../../constants/use-case-logo-names.js"

import { HiOutlineArrowNarrowRight } from "@react-icons/all-files/hi/HiOutlineArrowNarrowRight"
import { AiOutlineCloseCircle } from "@react-icons/all-files/ai/AiOutlineCloseCircle"
import "./useCasesCard.scss"

const UseCasesCard = ({ data }) => {
  const [selected, setSelected] = useState(null)
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null)
    }
    setSelected(i)
  }
  return (
    <Grid container spacing={2}>
      {data.map((item, i) => {
        const key = item.data.Company_Logo_Filename
        const fileName = imgFilenameMap[key]
        const filePath = fileName ? `/images/logos/${fileName}` : NoPreviewImg
        return (
          <Grid key={i} item sm={6} className="accordionContainer">
            <Grid className="accordion">
              <Grid sx={{ borderRadius: 5 }} className="item">
                <Grid container spacing={1} className="cardContainer">
                  <Grid item xs={4}>
                    <img
                      style={{ maxWidth: "100%" }}
                      src={filePath}
                      alt={item.data.Name}
                      className="image"
                    />
                  </Grid>
                  <Grid item xs={8} className="contentContainer">
                    <Typography
                      sx={{
                        fontSize: { sm: "12px", md: "25px" },
                        color: "#383D3B",
                        marginTop: 2,
                      }}
                      gutterBottom
                      fontFamily="Roboto Condensed, sans-serif"
                      fontWeight={550}
                    >
                      {item.data.Name}
                    </Typography>
                    {selected === i ? (
                      <Typography
                        sx={{ fontSize: { sm: "10px", md: "16px" } }}
                        className="showDescription"
                      >
                        {item.data.Description}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{ fontSize: { sm: "10px", md: "16px" } }}
                        className="hideDescription"
                      >
                        {item.data.Description}
                      </Typography>
                    )}

                    {selected === i ? (
                      <AiOutlineCloseCircle
                        onClick={() => toggle(i)}
                        className="closeBtn"
                      />
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: 2,
                        }}
                      >
                        <Button
                          sx={{
                            fontSize: { sm: 8, lg: 16 },
                            fontWeight: 700,
                            color: "#1B4AF0",
                          }}
                          fontFamily="Roboto Condensed, sans-serif"
                          onClick={() => toggle(i)}
                        >
                          Read More{" "}
                          <HiOutlineArrowNarrowRight
                            style={{ marginLeft: 4 }}
                          />
                        </Button>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default UseCasesCard
