import React from "react"
import { Grid, Typography, Container, Button, Box } from "@mui/material"
import { RiArrowRightCircleLine } from "@react-icons/all-files/ri/RiArrowRightCircleLine"

import { StaticImage } from "gatsby-plugin-image"
const UseCasesServices = () => {
  return (
    <Grid
      sx={{
        background: "white",
        paddingY: { xs: 3, sm: 0, lg: 3 },
      }}
    >
      <Container maxWidth="xl">
        <Box sx={{ mx: "auto" }}>
          <Grid
            container
            sx={{
              paddingY: 1,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              style={{ maxWidth: { xs: "100%", md: "40%" } }}
            >
              <Typography
                sx={{
                  color: "black",
                  fontSize: { xs: 20, sm: 25, lg: 60 },
                  textAlign: { xs: "center", sm: "start" },
                  textTransform: { xs: "uppercase", sm: "capitalize" },
                }}
                component="h1"
                fontFamily="Roboto Condensed, sans-serif"
                fontWeight={700}
              >
                Check Out <span style={{ color: "#163BBF" }}>Our Services</span>
              </Typography>
              <Typography
                variant="h6"
                fontFamily="Roboto Condensed, sans-serif"
                fontWeight={700}
                component="h2"
                sx={{
                  marginTop: { xs: 2, sm: 1, lg: 2 },
                  color: "#FFBF66",
                  textTransform: "uppercase",
                  fontSize: { xs: 16, sm: 12, lg: 32 },
                  textAlign: { xs: "center", sm: "start" },
                }}
              >
                we got you covered
              </Typography>
              <Box
                sx={{
                  display: { xs: "flex", sm: "none" },
                  justifyContent: "center",
                  marginY: 3,
                }}
              >
                <StaticImage
                  style={{ width: "70%" }}
                  src="../../images/Illustration.png"
                  alt="Illustration Image"
                />{" "}
              </Box>
              <Typography
                sx={{
                  marginTop: { xs: 5, sm: 2, lg: 5 },
                  color: "black",
                  fontSize: { xs: 16, sm: 10, md: 25 },
                }}
                fontFamily="Roboto Condensed, sans-serif"
                fontWeight={400}
              >
                Cassandra Link is a resources page that is part of the Anant US
                company. Anant US is a full-service organization that offers a
                wide range of services, from software development to business
                consulting.
              </Typography>
              <Typography
                sx={{
                  marginTop: { xs: 5, sm: 2, lg: 5 },
                  color: "black",
                  fontSize: { xs: 16, sm: 10, md: 25 },
                }}
                fontFamily="Roboto Condensed, sans-serif"
                fontWeight={400}
              >
                The resources on the Cassandra Link page are just one example of
                the many ways that Anant US helps its clients succeed.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "start" },
                  marginTop: { xs: "10px", sm: "14px", md: "40px" },
                }}
              >
                <a href="https://anant.us/">
                  <Button
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "#163BBF",
                      fontSize: { xs: "14px", sm: "10px", md: "18px" },
                      textTransform: "uppercase",
                      "&:hover": {
                        backgroundColor: "#163BBF",
                      },
                    }}
                    fontFamily="Roboto Condensed, sans-serif"
                    fontWeight={700}
                    variant="contained"
                    endIcon={<RiArrowRightCircleLine size={"20px"} />}
                  >
                    Go to website
                  </Button>
                </a>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                justifyContent: "flex-end",
                alignItems: "center",
                display: { xs: "none", sm: "flex" },
              }}
            >
              <StaticImage
                style={{ width: "70%" }}
                src="../../images/Illustration.png"
                alt="Illustration Image"
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Grid>
  )
}

export default UseCasesServices
