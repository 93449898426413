import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Seo/Seo"
import { Container, Grid } from "@mui/material"
import UseCasesCard from "../components/UseCases/UseCasesCard/UseCasesCard"
import PageTitle from "../components/PageTitle/PageTitle"
import UseCasesLayout from "../layouts/UseCasesLayout"
import { Helmet } from "react-helmet"
const useCases = () => {
  const data = useStaticQuery(graphql`
    query CompanyQuery {
      company: allAirtable(
        filter: { table: { eq: "Company" } }
        sort: { fields: data___Name, order: ASC }
      ) {
        nodes {
          data {
            Name
            Description
            Company_Logo_Filename
          }
          recordId
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <title>Use Cases - Cassandra Link</title>
        <meta property="og:title" content="Use Cases - Cassandra Link" />
        <meta
          name="description"
          content="Explore the various use cases for Cassandra Link"
        />
        <meta
          property="og:description"
          content="Explore the various use cases for Cassandra Link"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://cassandra.link/",
            "@type": "WebPage",
            name: "Use Cases - Cassandra Link",
            keywords: "Cassandra Link, Use Cases, Cassandra",
            author: {
              "@type": "Organization",
              name: "Cassandra Link",
            },
          })}
        </script>
        <meta property="og:title" content={"Cassandra Link - Use Cases"} />

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={"Cassandra Link - Use Cases"} />
        <meta name="twitter:image" content={"../../images/Logo.svg"} />
      </Helmet>
      <Grid>
        {/* <PageTitle title="Use Cases" />
          <UseCasesCard data={data.company.nodes} /> */}
        <UseCasesLayout data={data.company.nodes} />
      </Grid>
    </Layout>
  )
}

export default useCases
