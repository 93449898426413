import { Grid } from "@mui/material"
import React from "react"
import ContributingSection from "../components/AboutUs/Contribute/ContributingSection"
import ContributeBackgroundImage from "../images/ContributeBG.png"
import UseCasesPagination from "../components/UseCases/UseCasesPagination"
import UseCasesBenefits from "../components/UseCases/UseCasesBenefits"
import UseCasesServices from "../components/UseCases/UseCasesServices"
function UseCasesLayout({ data }) {
  return (
    <Grid container>
      <Grid item xs={12}></Grid>
      <Grid item xs={12} sx={{ backgroundColor: "#EEEEEE" }}>
        <ContributingSection
          showUseCasesCards={true}
          title={"Real-world use cases for"}
          highlightTitle={"Apache Cassandra"}
          highlightTitleColor={"#FFA62B"}
          description={
            "Discover how Apache Cassandra is helping organizations of all sizes and industries to build scalable, high-performance applications that can handle massive amounts of data while ensuring high availability and low latency. From financial services to healthcare, retail to telecommunications, learn how companies are leveraging the power of Apache Cassandra to drive innovation, improve operational efficiency, and deliver superior customer experiences."
          }
        />
      </Grid>
      <Grid item xs={12}>
        <UseCasesBenefits />
      </Grid>
      <Grid item xs={12}>
        <UseCasesPagination data={data} />
      </Grid>
      <Grid
        sx={{
          backgroundImage: `url(${ContributeBackgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        item
        xs={12}
      >
        <ContributingSection />
      </Grid>
      <Grid item xs={12}>
        <UseCasesServices />
      </Grid>
    </Grid>
  )
}

export default UseCasesLayout
