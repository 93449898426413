import React from "react"
import { Typography, Grid, Box, Container } from "@mui/material"
import ContributeCard from "./ContributeCard"
function ContributingSection({
  title,
  highlightTitle,
  highlightTitleColor,
  description,
  showUseCasesCards,
}) {
  const contributingCards = [
    {
      title: "Add content in our Awesome-Cassandra Repository",
      description:
        "Enrich Awesome-Cassandra: Share valuable resources and projects on repository.",
      isAddingContent: true,
    },
    {
      title: "Create an issue with an idea for the project",
      description:
        "Suggest and report: Share your thoughts with the repository community.",
      isAddingContent: false,
    },
  ]
  const useCasesCards = [
    {
      title: "Watch video(s)",
      description:
        "Unlock the power of Apache Cassandra and take your data management skills to the next level with our engaging and informative video tutorials.",
      isVideoList: true,
    },
    {
      title: "Learn More",
      description:
        "Master the art of high-performance, distributed database management with our comprehensive courses on Apache Cassandra.",
      isAddingContent: false,
    },
  ]
  return (
    <Container maxWidth="xl" sx={{ marginY: { xs: "50px", lg: "150px" } }}>
      <Grid container spacing={{ sm: "10px", lg: "168px" }}>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginBottom: { xs: 4, sm: 0 },
          }}
        >
          <Box sx={{ marginTop: { xs: 5, sm: 0 } }}>
            <Typography
              sx={{
                textTransform: "uppercase",
                fontSize: { xs: "25px", sm: "32px", lg: "80px" },
                color: "#383D3B",
                fontWeight: 700,
                fontFamily: "Roboto condensed, sans-serif",
                lineHeight: { xs: "32px", sm: "40px", lg: "90px" },
              }}
            >
              {title ? title : "Interested in"}{" "}
              <span
                style={{
                  color: highlightTitleColor ? highlightTitleColor : "#163BBF",
                }}
              >
                {" "}
                {highlightTitle ? highlightTitle : "contributing?"}
              </span>
            </Typography>
            {/* <Typography
              sx={{
                textTransform: "uppercase",
                fontSize: { xs: "25px", sm: "32px", lg: "80px" },
                fontWeight: 700,
                color: highlightTitleColor ? highlightTitleColor : "#163BBF",

                fontFamily: "Roboto condensed, sans-serif",
                lineHeight: { xs: "32px", sm: "40px", lg: "90px" },
              }}
            >
              {highlightTitle ? highlightTitle : "contributing?"}
            </Typography> */}
          </Box>
          <Typography
            sx={{
              fontFamily: "Roboto condensed, sans-serif",
              marginTop: { xs: "25px", sm: "50px" },
              fontSize: { xs: "13px", sm: "12px", lg: "22px" },
              lineHeight: { xs: "15px", lg: "26px" },
            }}
          >
            {description
              ? description
              : "  To contribute to Awesome-Cassandra, users can add content or create an issue. Adding content involves forking the repository, creating a new branch, and submitting a pull request. Users can also create an issue to suggest new features or report bugs. The issue tab on the repository is the place to go for new ideas or problems. "}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Grid container>
          {showUseCasesCards
            ? useCasesCards.map((card) => (
               <Grid  key={card.title} item xs={12}> 
                <ContributeCard
                 
                  title={card.title}
                  description={card.description}
                  image={card.image}
                  isVideoList={card.isVideoList}
                />
               </Grid>
              ))
            : contributingCards.map((card) => (
                <Grid item xs={12} key={card.title}>
                  <ContributeCard
                  key={card.title}
                  title={card.title}
                  description={card.description}
                  isAddingContent={card.isAddingContent}
                 
                /> </Grid>
              ))}
          </Grid>
        
        </Grid>
      </Grid>
    </Container>
  )
}

export default ContributingSection
