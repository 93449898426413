import { Box, Typography, Grid } from "@mui/material";
import React from "react";
import { TbChecklist } from "react-icons/tb";
import { MdLibraryAddCheck } from "react-icons/md";
import { Link } from "gatsby";
import { AiFillPlayCircle } from "react-icons/ai";

const ContributeCard = ({ title, description, isAddingContent,isVideoList }) => {
  return (
    <Link disabled={!isVideoList} to={isVideoList ? 'https://cassandra.link/videos/page/1' : '/use-cases' }>
      <Box
        sx={{
          width: '100%',
          backgroundColor: "#FEFEFE",
          borderRadius: "3px",
          paddingY: { xs: 2, lg: 5 },
          paddingX: { xs: 2.5, lg: 4 },
          display: "flex",
          justifyContent: "center",
          marginBottom: { xs: 3 },
          borderBottom: {
            xs: "4px solid transparent",
            sm: "10px solid transparent",
          },
          borderRadius: "4px",
          cursor: "pointer",
          transition: "0.3s all ease-in",
          ":hover": { borderBottomColor: "#FFA62B" },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <Box
              sx={{
                height: { xs: "20px", lg: "42px" },
                width: { xs: "20px", lg: "42px" },
                color: "#FFA62B",
                transition: "0.3s all ease-in",
                marginRight: { xs: 1, sm: 1.5, lg: 3 },
                ":hover": { color: "#163BBF" },
              }}
            >
              {isAddingContent ? (
                <TbChecklist style={{ width: "inherit", height: "inherit" }} />
              ) : isVideoList ? (
                <AiFillPlayCircle style={{ width: "inherit", height: "inherit" }} />
              ) : (
                <MdLibraryAddCheck
                  style={{ width: "inherit", height: "inherit" }}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={10}>
           <Box sx={{marginLeft:2}}>
           <Typography
              sx={{
                fontFamily: "Roboto condensed,sans-serif",
                fontSize: { xs: "16px", lg: "32px" },
                fontWeight: 550,
                lineHeight: { xs: "19px", lg: "26px" },
                display: "flex",
                marginBottom: { xs: 1, lg: 2 },
                color: "#383D3B",
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Roboto condensed,sans-serif",
              
                color: "#383D3B",
                fontSize: { xs: "10px", sm: "11px", lg: "22px" },
                lineHeight: { xs: "13px", sm: "14px", lg: "26px" },
              }}
            >
              {description}
            </Typography>
           </Box>
          </Grid>
        </Grid>
      </Box>
    </Link>
  );
};

export default ContributeCard;
